<template>
  <div class="detail titlesTop">
    <headTitle :title="detailObj.peopleOrderRemark" />
    <div class="backCon">
      <div class="head_wrapper">
        <img class="flag_img" src="../../assets/images/redflag.png" alt />
        <span>服务点单</span>
      </div>
      <p class="fankui_content">
        <span style="font-weight:700">活动类型</span>
        <span class="small_span">{{detailObj.serviceTypeName}}</span>
      </p>
      <p class="fankui_content">
        <span style="font-weight:700">反馈状态</span>
        <span class="small_span">{{detailObj.peopleOrderStatus=="03"?"已反馈":"待反馈"}}</span>
      </p>
      <p class="fankui_content" v-if="detailObj.peopleName">
        <span style="font-weight:700">点单人员</span>
        <span class="small_span">{{detailObj.peopleName}}</span>
      </p>
      <p class="fankui_content">
        <span style="font-weight:700">活动需求</span>
        <span class="small_span">{{detailObj.peopleOrderRemark}}</span>
      </p>
    </div>
    <div class="backCon">
      <div class="head_wrapper">
        <img class="flag_img" src="../../assets/images/redflag.png" alt />
        <span>点单反馈</span>
      </div>
      <p class="fankui_content" v-if="!detailObj.logList">暂无反馈，请耐心等待!</p>
      <template v-else>
        <div
          class="fankui_content logListItem"
          v-for="(item,index) in detailObj.logList "
          :key="index"
        >
          <p class="title">
            {{item.peopleOrderLog}}
            <span
              v-if="item.peopleOrderStatus!='03'"
            >到{{item.handleRegionName}}</span>
          </p>
          <p
            v-if="index===detailObj.logList.length-1&&detailObj.peopleOrderFeedBack!==''"
          >回复：{{detailObj.peopleOrderFeedBack}}</p>
          <div class="time">{{item.createTime}}</div>
        </div>
      </template>
    </div>

    <div style="display:flex;justify-content: center;">
      <van-button
        type="danger"
        @click="reOrder"
        class="submit"
        v-if="detailObj.isFeedBack=='01'"
        :disabled="disableedFlag"
      >催 单</van-button>
    </div>
    <van-overlay :show="success_show">
      <div class="wrapper" @click.stop>
        <div class="success_block">
          <div class="success_head">
            <img src="../../assets/images/cuidan.png" alt />
          </div>
          <p>催单成功</p>
          <span>您的点单已查看，请耐心等待</span>
          <button @click="success_show = false">我知道了</button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Toast } from "vant";
import peopleOrderApi from "@/api/peopleOrder";
export default {
  data() {
    return {
      detailObj: {},
      mainId: null,
      disableedFlag: false,
      success_show: false, // 反馈催单弹窗
    };
  },
  created() {

    if (this.$route.query.id) {
      this.mainId = this.$route.query.id;
      this.getPeopleOrderInfo()
    } else {
      this.$router.push({
        path: '/massorder'
      })
    }

  },
  methods: {
    reOrder() {
      if (this.isToken()) {
        //   催单
        this.$http
          .get(this.javalinkTwo + `activity/peopleOrder/urgeOrder/${this.mainId}`)
          .then(res => {
            if (res.data.code === 10000) {
              Toast(`催单成功`)
              this.disableedFlag = true;
            } else {
              Toast(`${res.data.message}`)
            }
          });
      }
    },
    goback() {
      const page = this.$route.query.page;
      this.$router.push(`massorder?cate=fh&page=${page}`);
    },
    //接口
    getPeopleOrderInfo() {
      let params = {
        id: this.mainId
      }
      peopleOrderApi.getPeopleOrderInfo(params).then(res => {
        if (res.success) {
          console.log("fankui", res.data)
          this.detailObj = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.detail {
  background: #f7f7f7;
  padding-left: 3vw;
  padding-right: 3vw;

  .backCon {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 4vw;
    margin-top: 4vw;
    .head_wrapper {
      position: relative;
      span {
        position: absolute;
        top: 1vw;
        left: -1vw;
        font-size: 5vw;
        font-weight: bold;
        color: #fff;
      }
    }
    .flag_img {
      width: 30vw;
      margin: -4vw 0 0 -6vw;
    }
    p {
      text-align: left;
      margin: 10px 0px;
    }
    .fankui_content {
      font-size: 4vw;
      .small_span {
        margin-left: 10vw;
      }
    }
  }
  .wrapper {
    .success_block {
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      background: #fff;
      width: 80vw;
      margin: 50% auto 0;
      border-radius: 10px;
      .success_head {
        width: 40vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8vw;
        img {
          width: 80%;
        }
      }
      p {
        font-size: 7vw;
        color: #ff732e;
        font-weight: bold;
        margin: 4vw 0 3vw;
      }
      span {
        font-size: 4vw;
        color: #c0c0c0;
      }
      button {
        border-radius: 30px;
        border: none;
        width: 50vw;
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        background: #ff712b;
        color: #fff;
        font-size: 4vw;
        margin: 5vw 0;
      }
    }
  }
  .submit {
    width: 66vw;
    height: 12vw;
    line-height: 12vw;
    border-radius: 10px;
    font-size: 5vw;
    margin-top: 20vw;
  }
  .back {
    position: fixed;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    left: 10px;
    top: 16px;
    line-height: 20px;
    font-size: 20px;
  }
  .header {
    height: 1rem;
    background: #cd0102;
    text-align: center;
    line-height: 1rem;
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
  }
  .logListItem {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .title {
      // width:100%;
      word-break: break-all;
      line-height: 0.7rem;
    }
    .time {
      color: #666;
      font-size: 13px;
      width: 100%;
      text-align: right;
    }
  }
}
</style>